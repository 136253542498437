import React, { useEffect, useState } from "react";
import Slider from "./components/slider/Slider";
import AboutTemple from "./components/abouttemple/AboutTemple";
import Sportlight from "./components/spotlight/Sportlight";
import Panchangam from "./components/panchangam/Panchangam";
import Gallery from "./components/gallery/Gallery";
import Services from "./components/home-services/Services";
import Templetime from "./components/temple-time/Templetime";
import Newsupdate from "./components/newsupdate/Newsupdate";
import Yogaclass from "./components/yogaclass/Yogaclass";
import FloatingSidemenu from "./components/floatingmenu/FloatingSidemenu";
import PopupPage from "./components/popupLoad/PopupPage";
import useLoadMasterDataHome from "./customHooks";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import NewsEventsSection from "./components/panchangam/NewsEventsSection";

function Home() {
  const { masterData, getMasterData, gallery } = useLoadMasterDataHome();
  const [templeConfig, setTempleConfig] = useState([]);

  const getTempleConfig = async () => {
    const response = await getApi(
      `${apiEndPoints?.getTempleConfigByCode}/${process.env.REACT_APP_TEMPLE_CODE}`
    );
    setTempleConfig(response?.data?.data?.sections);
  };

  useEffect(() => {
    getMasterData();
    getTempleConfig();
  }, []);

  const getSubsectionStatus = (
    sectionName,
    subSectionName,
    TempleConfigData
  ) => {
    const section = TempleConfigData?.find((item) => item?.name == sectionName);
    const subsection = section?.subsections?.find(
      (item) => item?.name == subSectionName
    );

    return section?.status && subsection?.status;
  };

  return (
    <>
      {/* <PopupPage /> */}

      <Slider slider={masterData.slider} />

      <Newsupdate
        monthlyNewsletter={masterData.monthlyNewsletter}
        annualCalendar={masterData.annualCalendar}
        latestNews={masterData.latestNews}
      />

      <NewsEventsSection
        dailyPanchangam={masterData.dailyPanchangam}
        dailyNews={masterData.dailyNews}
        allEvents={masterData.allEvents}
        upcomingEvents={masterData.upcomingEvents}
        getSubsectionStatus={getSubsectionStatus}
        templeConfig={templeConfig}
      />

      {getSubsectionStatus("Services", "Services At Temple", templeConfig) && (
        <Services services={masterData.services} />
      )}

      <Sportlight spotlight={masterData.spotlight} />
      <AboutTemple />
      <Panchangam
        dailySchedule={masterData.dailySchedule}
        dailyPanchangam={masterData.dailyPanchangam}
        dailyNews={masterData.dailyNews}
        allEvents={masterData.allEvents}
        getSubsectionStatus={getSubsectionStatus}
        templeConfig={templeConfig}
      />
      <Gallery gallery={gallery} />
      <Templetime />
      {/* <Yogaclass /> */}
      <FloatingSidemenu
        liveStreamingUrlData={masterData?.liveStreamingUrlData}
      />
    </>
  );
}

export default Home;
