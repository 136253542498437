import * as React from "react";
import "./monthlyevents.css";
// import { Box, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TodayIcon from "@mui/icons-material/Today";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useState } from "react";
import Today from "./Today";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Link } from "react-router-dom";
function MonthlyEvents(upcomingEvents) {
  const [tab, setTab] = useState("Monthly");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <React.Fragment>
        <Box title={tab}>
          <Box>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tab}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <div className="title-bg d-flex justify-content-around align-items-center">
                    <div>
                      <h2>Upcoming Events</h2>
                    </div>
                    <div>
                      <TabList
                        centered
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        textColor="blue"
                        indicatorColor="blue"
                        sx={{
                          "& .MuiTabs-indicator": {
                            backgroundColor: "white",
                          },
                          "& .css-1wf8b0h-MuiTabs-flexContainer": {
                            height: "30px",
                          },
                        }}
                      >
                        <Tab
                          icon={
                            <CalendarMonthIcon
                              sx={{
                                "&.MuiTab-iconWrapper": {
                                  marginRight: "1px !important",
                                },
                              }}
                            />
                          }
                          iconPosition="start"
                          label="Monthly"
                          value="Monthly"
                          sx={{
                            textTransform: "inherit",
                            height: "100%",
                            color: "white",
                            padding: "10px",
                            minHeight: "50px",
                            "&.Mui-selected": { color: "white" },
                          }}
                        />

                        <Tab
                          icon={
                            <DateRangeIcon
                              sx={{
                                "&.MuiTab-iconWrapper": {
                                  marginRight: "1px !important",
                                },
                              }}
                            />
                          }
                          iconPosition="start"
                          label="Weekly"
                          value="Weekly"
                          sx={{
                            textTransform: "inherit",
                            height: "100%",
                            color: "white",
                            padding: "10px",
                            minHeight: "50px",
                            "&.Mui-selected": { color: "white" },
                          }}
                        />
                        <Tab
                          icon={
                            <TodayIcon
                              sx={{
                                "&.MuiTab-iconWrapper": {
                                  marginRight: "1px !important",
                                },
                              }}
                            />
                          }
                          iconPosition="start"
                          label="Today"
                          value="Today"
                          sx={{
                            textTransform: "inherit",
                            color: "white",
                            height: "100%",
                            padding: "10px",
                            minHeight: "50px",
                            "&.Mui-selected": { color: "white" },
                          }}
                        />
                      </TabList>
                    </div>
                  </div>
                  <div className="sub-title-bg">
                    <a href="/calenderview">
                      <CalendarMonthIcon /> Calendar View
                    </a>
                  </div>
                </Box>
                <Box
                  sx={{
                    maxHeight: "428px",
                    width: "100%",
                    overflowY: "auto",
                    backgroundColor: "#ffffff",
                    height: "428px",
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                  }}
                >
                  <TabPanel value="Today">
                    <Today events={upcomingEvents?.upcomingEvents?.today} />
                  </TabPanel>
                  <TabPanel value="Weekly">
                    <Weekly events={upcomingEvents?.upcomingEvents?.weekly} />
                  </TabPanel>
                  <TabPanel value="Monthly">
                    <Monthly events={upcomingEvents?.upcomingEvents?.monthly} />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>
          <div className="bottom-more">
            <Typography variant="p">
              <Link
                to="/calenderview?view=agenda"
                className="btn text-white py-2 px-4"
              >
                View all
              </Link>
            </Typography>
          </div>
        </Box>
      </React.Fragment>
    </>
  );
}

export default MonthlyEvents;
