import { useState } from "react";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import moment from "moment";

function useLoadMasterDataHome() {
  const [gallery, setGallery] = useState();
  const [masterData, setMasterData] = useState({
    slider: null,
    monthlyNewsletter: null,
    annualCalendar: null,
    latestNews: null,
    spotlight: null,
    services: null,
    liveStreamingUrlData: null,
    dailyPanchangam: null,
    dailyNews: null,
    allEvents: null,
    upcomingEvents: null,
  });

  const getMasterData = async () => {
    const date = moment().tz("America/New_York").format("ddd, DD MMM YYYY ");
    const payload = {
      date,
    };
    const newsPayload = {
      limits: 5,
    };
    const result = await Promise.all([
      getApi(apiEndPoints.slider),
      getApi(apiEndPoints.monthly_newsletter),
      getApi(apiEndPoints.annualCalendar),
      getApi(apiEndPoints.scroller),
      getApi(apiEndPoints.spotlight),
      getApi(apiEndPoints.allServices),
      getApi(apiEndPoints.liveStreamingUrl),
      postApi(apiEndPoints.panchang, payload),
      postApi(apiEndPoints.news, newsPayload),
      getApi(apiEndPoints.allHomePageEvents),
      postApi(apiEndPoints.getUpcomingEvents),
    ]);

    setMasterData({
      slider: result[0]?.data?.data,
      monthlyNewsletter: result[1]?.data?.data,
      annualCalendar: result[2]?.data?.data,
      latestNews: result[3]?.data?.data,
      spotlight: result[4]?.data?.data,
      services: result[5]?.data?.data,
      liveStreamingUrlData: result[6]?.data?.data[0],
      dailyPanchangam: result[7]?.data?.data,
      dailyNews: result[8]?.data?.data,
      allEvents: result[9]?.data?.data,
      upcomingEvents: result[10]?.data,
    });

    const [galleryList, galleryCategories, galleryFestivals, galleryTags] =
      await Promise.all([
        postApi(apiEndPoints.allGallery, payload),
        getApi(apiEndPoints.galleryCategories),
        getApi(apiEndPoints.galleryFestivals),
        getApi(apiEndPoints.galleryTags),
      ]);

    setGallery({
      list: galleryList?.data?.status == 1 ? galleryList?.data?.data : [],
      categories:
        galleryCategories?.data?.status == 1
          ? galleryCategories?.data?.data
          : [],
      festival:
        galleryFestivals?.data?.status == 1 ? galleryFestivals?.data?.data : [],
      tags: galleryTags?.data?.status == 1 ? galleryTags?.data?.data : [],
    });
  };
  return {
    masterData,
    getMasterData,
    gallery,
    setMasterData,
  };
}

export default useLoadMasterDataHome;
