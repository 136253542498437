import React, { useState, useEffect } from "react";
import "./dayschedule.css";
import { Box } from "@mui/material";
import { postApi } from "../../../../ApiServices/api";
import { apiEndPoints } from "../../../../ApiServices/config";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

function Dayschedule() {
  const [todaySchedule, setTodaySchedule] = useState([]);
  const [date, setDate] = useState(
    moment().tz("America/New_York").format("YYYY-MM-DD")
  );

  function formatDateForDisplay(date) {
    return moment(date).tz("America/New_York").format("ddd, DD MMM YYYY");
  }
  const updateDate = (days) => {
    const newDate = moment(date).add(days, "days").format("YYYY-MM-DD");
    setDate(newDate);
  };

  const payload = {
    date: date,
  };

  const getSchedule = async () => {
    try {
      const response = await postApi(apiEndPoints.todaySchedule, payload);
      setTodaySchedule(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDateForSchedule = () => {};
  useEffect(() => {
    getSchedule();
  }, [date]);

  return (
    <>
      <React.Fragment>
        <div className="title-bg ">
          <h2>Day Schedule</h2>
        </div>
        <div className="sub-title-bg d-flex justify-content-around">
          <div>
            <KeyboardDoubleArrowLeftIcon
              className="arw-btn"
              onClick={() => updateDate(-1)}
            />
          </div>
          <div>
            <h2>{formatDateForDisplay(date)}</h2>
          </div>
          <div>
            <KeyboardDoubleArrowRightIcon
              className="arw-btn"
              onClick={() => updateDate(1)}
            />
          </div>
        </div>
        <div className="panchang-content">
          <div className="text-center h-100 pt-0 panchang">
            <div className="panchang-content2">
              <Box
                sx={{
                  p: 3,
                  maxHeight: "400px",
                  overflowY: "auto",
                  width: "100%",
                }}
              >
                <ul className="dayschedulel-list">
                  {todaySchedule?.map((Schedule, i) => (
                    <li key={i}>
                      <span>{Schedule.time}</span>
                      {Schedule.title}
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default Dayschedule;
