import React, { useEffect } from "react";
import "./balgokul.css";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormHelperText, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller, useForm } from "react-hook-form";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function BalGokulPayment() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("Data :- ", data);

    const payload = {
      productData: [
        {
          registeration_no: data?.registeration_no,
          price: Number(data?.amount),
          description: data?.description,
          no_students: data?.no_students,
          email: data?.email,
        },
      ],
    };
    const response = await postApi(apiEndPoints?.balGokulPayment, payload);
    localStorage.setItem("orderId", response.data.orderData._id);
    setTimeout(() => {
      window.location.href = response?.data?.url;
    }, 1000);
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          BalGokul Registration Payment
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">BalGokul Payment</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            BalGokul
          </h6>
          <h1 className="mb-4">
            <span className="text-secundary">Registration Payment</span>
          </h1>
        </div>
        <div className="row">
          <div className="col-md-6 centre-align">
            <TableContainer>
              <Typography style={{ bottom: 5 }}>
                Please enter the Registration payment details:
              </Typography>
              <ul className="mt-2 mb-4 report-list">
                <li>
                  1. Complete the{"  "}
                  <Link to="https://forms.gle/VQD93Tz721koCzrY9">
                    Durga BalGokul Registration Form&nbsp;
                  </Link>
                  prior to making a payment
                </li>
                <li>2. Provide details below to submit payment</li>
              </ul>

              <Table sx={{ maxWidth: 560 }} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      DBG Registration ID:
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth>
                        <TextField
                          label="Registration No "
                          id="fullWidth"
                          {...register("registeration_no", {
                            required: "Registration number is required",
                            pattern: {
                              value: /^UID-DBG\d{5}$/,
                              message:
                                "Must be in the format UID-DBG followed by 5 digits",
                            },
                            maxLength: {
                              value: 12,
                              message:
                                "Registration number must be exactly 12 characters",
                            },
                            minLength: {
                              value: 12,
                              message:
                                "Registration number must be exactly 12 characters",
                            },
                          })}
                          error={errors?.registeration_no?.message}
                          helperText={errors?.registeration_no?.message}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      DBG Registered Email:
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth>
                        <TextField
                          label="Email"
                          id="email"
                          {...register("email", {
                            required: "Email is required",
                          })}
                          error={errors?.email?.message}
                          helperText={errors?.email?.message}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Number of Students :
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Number of Students:
                        </InputLabel>
                        <Controller
                          name="no_students"
                          control={control}
                          rules={{ required: "Number of students is required" }}
                          render={({ field }) => (
                            <>
                              <Select
                                {...field}
                                align="right"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Number of Students"
                                error={errors?.no_students?.message}
                                helperText={errors?.no_students?.message}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                              {errors.no_students && (
                                <FormHelperText error>
                                  {errors.no_students.message}
                                </FormHelperText>
                              )}{" "}
                            </>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Description :
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth>
                        <TextField
                          style={{ width: 310 }}
                          label="Description"
                          id="fullWidth"
                          {...register("description", {
                            required: "Description is required",
                          })}
                          error={errors?.description?.message}
                          helperText={errors?.description?.message}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Amount :
                    </TableCell>
                    <TableCell align="right">
                      <FormControl fullWidth>
                        <TextField
                          label="Amount"
                          id="fullWidth"
                          {...register("amount", {
                            required: "Amount is required",
                          })}
                          error={errors?.amount?.message}
                          helperText={errors?.amount?.message}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <Button
                        className="cont-btn"
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, p: 2 }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Payment
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default BalGokulPayment;
