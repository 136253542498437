import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { galleryType } from "./ApiServices/config";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import HomePageEvents from "./pages/home/HomePageEvents";
import HomePageNews from "./pages/home/HomePageNews";
import Registration from "./pages/registration/Registration";
import Login from "./pages/login/Login";
import ReligiousServices from "./pages/religiousServices/ReligiousServices";
import Events from "./pages/event/Events";
import EventDetails from "./pages/eventDetails/EventDetails";
import TempleHistory from "./pages/templeHistory/TempleHistory";
import TempleTrustees from "./pages/templeTrustees/TempleTrustees";
import TempleExecutiveBoard from "./pages/templeExecutiveBoard/TempleExecutiveBoard";
import CommitteeCoordinators from "./pages/committeeCoordinators/CommitteeCoordinators";
import ContactUs from "./pages/contactUs/ContactUs";
import RegistrationSuccess from "./pages/registerationSuccess/RegistrationSuccess";
import Profile from "./pages/profile/Profile";
import ForgotPassword from "./pages/forgetPassword/ForgotPassword";
import ForgotPasswordSent from "./pages/forgetPassword/ForgotPasswordSent";
import MonthlyServices from "./pages/monthlyService/MonthlyServices";
import WeeklyServices from "./pages/weeklyService/WeeklyServices";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ChangePassword from "./pages/changePassword/ChangePassword";
import SpecialServices from "./pages/specialServices/SpecialServices";
import RecurringServices from "./pages/RecurringService/RecurringServices";
import Donations from "./pages/donation/Donations";
import Services from "./pages/services/Services";
import Community from "./pages/community/Community";
import Rentals from "./pages/rentals/Rentals";
import IndividualNews from "./pages/individualNews/IndividualNews";
import Gallery from "./pages/gallery";
import Photos from "./pages/gallery/photos";
import Videos from "./pages/gallery/Videos";
import ServicesAtTemple from "./pages/serviceAtTemple/ServicesAtTemple";
import Almanac from "./pages/almanac/Almanac";
import ServicesOutsideTemple from "./pages/serviceOutSideTemple/ServicesOutsideTemple";
import CheckAvailability from "./pages/checkAvailability/CheckAvailability";
import CheckAvailabilitySent from "./pages/checkAvailability/CheckAvailabilitySent";
import PaymentGateway from "./pages/payment";
import Sankalpam from "./pages/payment/checkoutDetails";
import Message from "./pages/payment/Message";
import ServicesPaymentGateway from "./pages/payment/servicesCheckout";
import Cancellation from "./pages/cancellation/Cancellation";
import TheTemple from "./pages/theTemple/TheTemple";
import TempleMission from "./pages/templeMission/TempleMission";
import AnnualServices from "./pages/annualServices/AnnualServices";
import VahanaSevas from "./pages/vahanSevaServices/VahanSevasServices";
import PersonalServices from "./pages/personalServices/PersonalServices";
import AllDonations from "./pages/allDonations/AllDonations";
import Hotels from "./pages/hotels/Hotels";
import LocationDirection from "./pages/locationDirection/LocationDirection";
import Faq from "./pages/faq/Faq";
import Guidelines from "./pages/Guidelines/Guidelines";
import SinglepageView from "./pages/singlePageView/SinglepageView";
import ExpressBookingServices from "./pages/expressBooking/ExpressBookings";
import PaymentReciept from "./pages/paymentRecipt/PaymentReciept";
import PrasadamServices from "./pages/prasadamservices/PrasadamServices";
import Catering from "./pages/prasadamservices/Catering";
import Cafeteria from "./pages/prasadamservices/Cafeteria";
import BalGokul from "./pages/balGokul/BalGokul";
import YouthTruth from "./pages/youthTruth/YouthTruth";
import MeraApne from "./pages/meraApne/MeraApne";
import HelpingOthers from "./pages/helpingOthers/HelpingOthers";
import CulturalActivities from "./pages/culturalActivities/CulturalActivities";
import CulturalEvents from "./pages/culturalEvents/CulturalEvents";
import NewsLetterSuccess from "./pages/newsLetterSuccess/NewsLetterSuccess";
import PrayerBooking from "./pages/prayerBooking/PrayerBooking";
import Priests from "./pages/preists/Priests";
import OtpSubscription from "./pages/otpSubscription/OtpSubscription";
import Volunteer from "./pages/volunteer/Volunteer";
import Tvgallery from "./pages/tvGallery/Tvgallery";
import PriestBooking from "./pages/priestBooking/PriestBooking";
import Education from "./pages/education/Education";
import Errorpage from "./components/Errorpage";
import PoojaSamagriList from "./pages/PoojaSamagriList";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import RecurringDonations from "./pages/recurreingDonation/RecurringDonations";
import ScrollToTopOnNavigate from "./components/common/ScrollToTopOnNavigate";
import PopUpNavigate from "./components/PopUpNavigate";
import PrasadamPaymentGateway from "./pages/payment/prasadmServicesCheckout";
import Search from "./pages/search/Search";
import LandDonation from "./components/home/donation/LandDonation";
import StoneDonation from "./components/home/donation/StoneDonation";
import PbjShelters from "./pages/pbjShelter/PbjShelters";
import ReligiousBooks from "./pages/religiousBooks/ReligiousBooks";
import BalgokulPayment from "./pages/balGokulPaymentt/BalGokulPayment";
import BalGokulRecipt from "./pages/payment/BalGokulCheckout";
import CalenderView from "./pages/home/components/calendarView/CalenderView";

function App() {
  const location = useLocation();

  return (
    <React.Fragment>
      <ScrollToTopOnNavigate />
      <PopUpNavigate />
      {location.pathname != "/tvgallery" && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/daily" element={<ReligiousServices />} />
        <Route path="/services/weekly" element={<WeeklyServices />} />
        <Route path="/services/monthly" element={<MonthlyServices />} />
        <Route path="/services/special" element={<SpecialServices />} />
        <Route path="/services/annual" element={<AnnualServices />} />
        <Route path="/services/recurring" element={<RecurringServices />} />
        <Route path="/services/vahana_sevas" element={<VahanaSevas />} />
        <Route path="/services/personal" element={<PersonalServices />} />
        <Route path="/donation" element={<AllDonations />} />
        <Route path="/servicesattemple" element={<ServicesAtTemple />} />
        <Route path="/outsidetemple" element={<ServicesOutsideTemple />} />
        <Route path="/expressbookings" element={<ExpressBookingServices />} />
        <Route path="/services" element={<Services />} />
        <Route path="/checkavailability" element={<CheckAvailability />} />
        <Route
          path="/checkavailabilitysent"
          element={<CheckAvailabilitySent />}
        />
        <Route path="/checkout-balGokul" element={<BalGokulRecipt />} />
        <Route path="/balgokul-payment" element={<BalgokulPayment />} />
        <Route path="/fullpanchang" element={<Almanac />} />
        <Route path="/donation/:donationcategory" element={<Donations />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/forgotpasswordsent" element={<ForgotPasswordSent />} />
        <Route
          path="/reset-password/:email_reset_token"
          element={<ResetPassword />}
        />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/registrationsuccess" element={<RegistrationSuccess />} />
        <Route path="/newsLettersuccess" element={<NewsLetterSuccess />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/events" element={<HomePageEvents />} />
        <Route path="/news" element={<HomePageNews />} />
        <Route path="/knowmoreevents/:slug" element={<Events />} />
        <Route path="/knowmorenews/:slug" element={<IndividualNews />} />
        <Route path="/eventdetails" element={<EventDetails />} />
        <Route path="/gallery">
          <Route
            path="photos"
            element={
              <Gallery type={galleryType.photo} key={galleryType.photo} />
            }
          />
          <Route path="photos/:slug" element={<Photos />} />
          <Route
            path="videos"
            element={
              <Gallery type={galleryType.video} key={galleryType.video} />
            }
          />
          <Route path="videos/:slug" element={<Videos />} />
        </Route>
        <Route path="/templehistory" element={<TempleHistory />} />
        <Route path="/templetrustees" element={<TempleTrustees />} />
        <Route path="/executiveboard" element={<TempleExecutiveBoard />} />
        <Route path="/coordinators" element={<CommitteeCoordinators />} />
        <Route path="/community" element={<Community />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route
          path="/payment-gateway/:bookingId"
          element={<PaymentGateway />}
        />
        <Route path="/recurringdonations" element={<RecurringDonations />} />
        <Route path="/payment-status" element={<Message />} />
        <Route path="/checkout-services" element={<ServicesPaymentGateway />} />
        <Route
          path="/prasadm-checkout-services"
          element={<PrasadamPaymentGateway />}
        />
        <Route path="/checkout-details" element={<Sankalpam />} />
        <Route path="/cancellation/:bookingId" element={<Cancellation />} />
        <Route path="/thetemple" element={<TheTemple />} />
        <Route path="/mission" element={<TempleMission />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route path="/location" element={<LocationDirection />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/services/:category/:slug" element={<SinglepageView />} />
        <Route path="/paymentreciept" element={<PaymentReciept />} />
        <Route path="/prasadamservices" element={<PrasadamServices />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/cafeteria" element={<Cafeteria />} />
        <Route path="/balgokul" element={<BalGokul />} />
        <Route path="/youthtruth" element={<YouthTruth />} />
        <Route path="/meraapne" element={<MeraApne />} />
        <Route path="/helpingothers" element={<HelpingOthers />} />
        <Route path="/activities" element={<CulturalActivities />} />
        <Route path="/culturalevents" element={<CulturalEvents />} />
        <Route path="/prayerbooking" element={<PrayerBooking />} />
        <Route path="/otp" element={<OtpSubscription />} />
        <Route path="/errorpage" element={<Errorpage />} />
        <Route path="/priestbooking" element={<PriestBooking />} />
        <Route path="/priests" element={<Priests />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/education" element={<Education />} />
        <Route path="/tvgallery" element={<Tvgallery />} />
        <Route path="/poojasamagri" element={<PoojaSamagriList />} />
        <Route
          path="/prasadm-checkout-services"
          element={<PrasadamPaymentGateway />}
        />
        <Route path="/search" element={<Search />} />
        <Route path="/recurringdonations" element={<RecurringDonations />} />
        <Route path="/landdonation" element={<LandDonation />} />
        <Route path="/stonedonation" element={<StoneDonation />} />
        <Route path="/pbj" element={<PbjShelters />} />
        <Route path="/calenderview" element={<CalenderView />} />
        <Route path="/religioustexts" element={<ReligiousBooks />} />
      </Routes>
      {location.pathname != "/tvgallery" && <Footer />}
    </React.Fragment>
  );
}

export default App;
